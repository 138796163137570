<template>
  <div class="question-steps__steps">
    <div
      v-for="(q, qKey) in stepQuestions"
      :key="qKey"
      class="d-flex align-items-start th-py-4"
      :class="{
        [stepClass]: true,
        done: currentQuestion > qKey + 1,
        active: currentQuestion === qKey + 1,
        visited: isVisited(qKey + 1),
      }"
      @click="previousStep(qKey + 1)"
    >
      <th-step
        class="mr-2 question-steps__step-count"
        :done="currentQuestion > qKey + 1"
        sm
      >
        <template slot="step">
          {{ qKey + 1 }}
        </template>
      </th-step>
      <div>
        {{ q.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ThStep from "../../GeneralComponents/ThStep";
import { questionByType } from "../../../utils/service/Questionnaire";

export default {
  name: "QuestionSteps",
  components: { ThStep },
  props: {
    stepClass: {
      type: String,
      default: "question-steps__step",
    },
  },
  data: () => ({
    title: "Document",
    loading: true,
  }),
  async beforeCreate() {
    // this.progress = await this.$axios.get("/api/v1/business/progress");
    await this.$store.dispatch("business/fetchBusinessProgress");
    this.loading = false;
  },
  computed: {
    stepQuestions() {
      return this.questions || questionByType(this.$route.params.type);
    },
    ...mapState("questionnaire", [
      "questions",
      "currentQuestion",
      "currentQuestionStatus",
    ]),
    ...mapState("business", ["progress"]),
  },
  methods: {
    qStatus(step) {
      return this.currentQuestionStatus.find(({ q }) => q === step) || {};
    },
    isVisited(step) {
      return !!this.qStatus(step)?.visited;
    },
    previousStep(step) {
      if (step >= this.currentQuestion && !this.isVisited(step)) return;

      this.SET_CURRENT_QUESTION(step);
    },
    ...mapMutations("questionnaire", ["SET_CURRENT_QUESTION"]),
  },
};
</script>
