<template>
  <div
    class="th-step"
    :class="{
      'th-step--done': done,
      'th-step--failed': failed,
      'th-step--sm': sm,
    }"
  >
    <span v-if="failed">
      <v-icon>mdi-close</v-icon>
    </span>
    <span v-else-if="done">
      <v-icon>mdi-check</v-icon>
    </span>
    <span v-else><slot name="step" /></span>
  </div>
</template>

<script>
export default {
  name: "th-step",
  props: {
    done: Boolean,
    failed: Boolean,
    sm: Boolean,
  },
};
</script>

<style lang="scss" scoped>
$stepSize: 24px;
$smStepSize: 20px;

.th-step {
  min-width: $stepSize;
  min-height: $stepSize;
  width: $stepSize;
  height: $stepSize;
  line-height: $stepSize;
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid lighten($primary, 35%);
  color: $primary;

  &--done {
    border: 1px solid lighten($primary, 35%);
    color: $light;
    background: $primary;
  }
  &--failed {
    border: 1px solid lighten($danger, 35%);
    color: $light;
    background: $danger;
  }

  &--sm {
    min-width: $smStepSize;
    min-height: $smStepSize;
    width: $smStepSize;
    height: $smStepSize;
    line-height: 1;
    font-size: 13px;
  }

  .v-icon {
    font-size: 14px !important;
    color: inherit !important;
  }
}
</style>
